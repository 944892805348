import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { fetchConfigureData, updateConfigureStatus } from '../../services/dashboard';
import { manageConfigure } from '../../store/dashboardConfigure/DashboardConfireSlice';
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';
export const Configure = () => {
    const configure = useAppSelector((state) => state.configuration.configure);
    const dispatch = useAppDispatch();
    const [generalConfigure, setGeneralConfigure]: any = useState({});
    const [dynamicConfigure, setDynamicConfigure]: any = useState({});
    const [mainDashboardConfigure, setMainDashboardConfigure]: any = useState({});

    useEffect(() => {
        fetchSettingsData();
    }, []);

    const fetchSettingsData = () => {
        dispatch(loadingStart());
        fetchConfigureData()
            .then((success) => {
                dispatch(loadingEnd());
                if (success.generalSetting) {
                    setGeneralConfigure(success.generalSetting);
                }
                if (success.dynamicSetting) {
                    setDynamicConfigure(success.dynamicSetting);
                }
                if (success.mainSetting) {
                    setMainDashboardConfigure(success.mainSetting)
                }
            })
            .catch((error) => {
                dispatch(loadingEnd());
                console.log('Error while fetch configure data', error);
            })
    }

    const updateStatus = (data: any) => {
        dispatch(loadingStart());
        updateConfigureStatus(data)
            .then((success) => {
                dispatch(loadingEnd());
                if (success && success.status.error === false) {
                    fetchSettingsData();
                }
            })
            .catch((error) => {
                dispatch(loadingEnd());
                console.log('Error while update configure status', error);
            })
    }
    
    return (
        <>
            <section className="middle-navbar px-30 py-4">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between">

                        <div className="d-flex align-items-center">
                            <div className="nav-title-txt">Setting & Configuration</div>
                        </div>
                        {/* <div className="stp-action d-flex align-items-center">
                            <button type="button" className="btn btn-outline-secondary"><i className="bi bi-date"></i> 03 March, 2022 to 31 March, 2022</button>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="setting-config-sec px-3 pt-4 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-9">
                            <div className="general-wconfig mb-4">
                                <div className="d-flex justify-content-between mb-2">
                                    <h6>General widgets</h6>
                                    <div className="opacity-50">Show/hide widgets in analytic dashboard</div>
                                </div>

                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Appointments
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.appointment}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, appointment: !generalConfigure.appointment } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Production
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.production}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, production: !generalConfigure.production } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Past due AR
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.pastDueAr}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, pastDueAr: !generalConfigure.pastDueAr } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Unscheduled Family
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.unscheduledFamily}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, unscheduledFamily: !generalConfigure.unscheduledFamily } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Patient Sentiment
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.patientSentimate}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, patientSentimate: !generalConfigure.patientSentimate } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                New/Unscheduled Patients
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={generalConfigure && generalConfigure.unscheduledPatient}
                                                onChange={() => {
                                                    updateStatus({ general: { ...generalConfigure, unscheduledPatient: !generalConfigure.unscheduledPatient } });
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="dynamic-wconfig">
                                <div className="d-flex justify-content-between mb-2">
                                    <h6>Dynamic widgets</h6>
                                    <div className="opacity-50">Show/hide widgets in analytic dashboard</div>
                                </div>

                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Active/Scheduled Patients
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.scheduledPatient}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, scheduledPatient: !dynamicConfigure.scheduledPatient } });
                                                }}
                                                
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Scheduled
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.scheduled}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, scheduled: !dynamicConfigure.scheduled } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                New Patients
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.newPatient}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, newPatient: !dynamicConfigure.newPatient } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Cancellations
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.Cancellation}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, Cancellation: !dynamicConfigure.Cancellation } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Re-Appointment
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.reAppointment}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, reAppointment: !dynamicConfigure.reAppointment } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Unscheduled
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.unscheduled}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, unscheduled: !dynamicConfigure.unscheduled } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Hygiene Treatment Plan
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.hygieneTreatmentPlan}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, hygieneTreatmentPlan: !dynamicConfigure.hygieneTreatmentPlan } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Restorative Treatment Plan
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.restorativeTreatmentPlan}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, restorativeTreatmentPlan: !dynamicConfigure.restorativeTreatmentPlan } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Provider Production
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.providerProductionAppointment}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, providerProductionAppointment: !dynamicConfigure.providerProductionAppointment } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Clinic Production
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.clinicProductionAppointment}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, clinicProductionAppointment: !dynamicConfigure.clinicProductionAppointment } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>
                                                Overdue
                                            </div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dynamicConfigure && dynamicConfigure.overdue}
                                                onChange={() => {
                                                    updateStatus({ dynamic: { ...dynamicConfigure, overdue: !dynamicConfigure.overdue } });
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Main Dashboard */}
                            <div className="dynamic-wconfig mt-3">
                                <div className="d-flex justify-content-between mb-2">
                                    <h6>Main Dashboard</h6>
                                </div>

                                <div className="card mb-3">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Review</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.review}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, review: !mainDashboardConfigure.review } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Patient Summary</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.patientSummary}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, patientSummary: !mainDashboardConfigure.patientSummary } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Requested</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.requested}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, requested: !mainDashboardConfigure.requested } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Waitlisted</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.waitlisted}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, waitlisted: !mainDashboardConfigure.waitlisted } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Completed</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.completed}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, completed: !mainDashboardConfigure.completed } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Unscheduled</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.unscheduled}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, unscheduled: !mainDashboardConfigure.unscheduled } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Due Immediately</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.dueImmediately}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, dueImmediately: !mainDashboardConfigure.dueImmediately } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Pre-appointed</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.preAppointed}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, preAppointed: !mainDashboardConfigure.preAppointed } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Cancelled</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.cancelled}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, cancelled: !mainDashboardConfigure.cancelled } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>New Patient</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.newPatient}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, newPatient: !mainDashboardConfigure.newPatient } });
                                                }}
                                            />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <div>Re-Scheduled</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.reScheduled}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, reScheduled: !mainDashboardConfigure.reScheduled } });
                                                }}
                                            />
                                        </li>
                                         <li className="list-group-item d-flex justify-content-between">
                                            <div>Overdue</div>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={mainDashboardConfigure && mainDashboardConfigure.overdue}
                                                onChange={() => {
                                                    updateStatus({ mainDashboard: { ...mainDashboardConfigure, overdue: !mainDashboardConfigure.overdue } });
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
