import React, { useState,useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../store/hook'
import { decrement, increment } from '../../store/counter/counterSlice'
import { Button, DatePicker, version } from "antd";
export const Counter = () => {
    const count = useAppSelector((state) => state.counter.value)
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.user);
    useEffect(() => {
        console.log('CounterUser',user);
           
    },[]);
    return (
        <div className='App'>
            <div>
                <h1 className='text-red-600'>Counter</h1>
            </div>
            <div>
                <h3 className='text-green-900'>Count is : {count}</h3>
                <Button type='primary' onClick={()=>dispatch(increment())}>Increment</Button>
                <Button type='primary' danger onClick={()=>dispatch(decrement())}>Decrement</Button>
            </div>
      </div>
    
  )
}
