import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { useAppSelector } from '../../store/hook';
export const GoalsHeader = ({handleSelectedClinic,handleIsMultipleClinicSelection,isMultiSelection}:any) => {
  const userData = useAppSelector((state) => state.user.user);
  const [clinics, setClinics] = useState([]);
  const [selectedClinics, setSelectedClinics]:any = useState([]);
  const [isMultipleSelection, setIsMultipleSelection] = useState(false);

  useEffect(() => {
    if (userData && userData.clinics && userData.clinics.length > 0) {
      let clinicList:any = userData.clinics.map((clinic: any) => { return { value: clinic.id, label: clinic.name, ...clinic } });
      // if (isMultipleSelection) {
      //   console.log('here0');
        
      //   clinicList.unshift({ value: 'all', label: 'All', id: 'all' });
      //   setSelectedClinics(clinicList[1]);
      // } else {
      //   console.log('here',clinicList[0]);
      //   setSelectedClinics(clinicList[0]);
      //   handleSelectedClinic([clinicList[0]]);
      // }
      setClinics(clinicList);
     
    }
  }, [userData, isMultiSelection]);


  const handleClinicList = (e: any) => {
    if (isMultipleSelection) {
      let findClinic = e.find((clinic: any) => { return clinic.id === 'all' });
      if (findClinic) {
        setSelectedClinics([clinics[0]]);
        handleSelectedClinic([clinics[0]]);
      } else {
        setSelectedClinics(e);
        handleSelectedClinic(e);
      }
    } else {
      setSelectedClinics(e);
      handleSelectedClinic([e]);
    }
  }

  const handleUpdate = () => {
    setIsMultipleSelection(true);
    handleIsMultipleClinicSelection(true);
    setSelectedClinics([selectedClinics]);
  }

  useEffect(() => {
     if (userData && userData.clinics && userData.clinics.length > 0) {
      let clinicList:any = userData.clinics.map((clinic: any) => { return { value: clinic.id, label: clinic.name, ...clinic } });
      if (isMultipleSelection && isMultiSelection) {
        clinicList.unshift({ value: 'all', label: 'All', id: 'all' });
        setSelectedClinics(selectedClinics);
      } else {
        setSelectedClinics(clinicList[0]);
        handleSelectedClinic([clinicList[0]]);
      }
      setClinics(clinicList);
     
    }
    if (!isMultiSelection) {
      setIsMultipleSelection(false);
      // setSelectedClinics(clinics[0]);
    }
  },[isMultiSelection]);

  return (
    <section className="middle-navbar px-30">
      <div className="p-0 container-fluid">
        <div className="d-flex justify-content-between">
          <div>
            <div className='d-flex flex-row'>
              <div className="nav-title-txt">Setup Goals</div>
              {
                !isMultipleSelection &&
                <div className='mx-3'>
                  <button className='btn btn-primary' onClick={() => { handleUpdate() }}>Update</button>
                </div>
              }
              
              <div className='d-flex justify-content-end mx-3'>
                <Select options={clinics} isMulti={isMultipleSelection}
                value={selectedClinics}
                onChange={(e: any) => {
                  handleClinicList(e)
                }}
              ></Select>
              </div>
              </div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <NavLink className="nav-link" to={"/goals/general"}>General</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={"/goals/production"}>Production</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
