import React from 'react'
import logo from '../../assets/images/logo/logo.svg';
import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export const Sidebar = () => {
  return (
    <>
      <section className="main-sidebar bg-light shrink">
        {/* <div className="profile-sec border rounded d-flex">
          <div className="avatar flex-shrink-0 avatar-lg img-circle me-2">
            <img src={logo} className="img-fluid" />
          </div>
          <div className="txt-box align-self-center">
            <div className="fw-medium charlmt side-bar-name" style={{ width: "150px" }}>{'Clinic name'}</div>
            <small className="text-black-50 side-bar-id">ID - {'12345678890'}</small>
          </div>
        </div> */}
        <div className="sidebar-menu">
          <ul className="p-0 main-menu-list">
            <li className="sidebar-items hasSubmenu">
              <NavLink to="/maindashboard" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-clipboard-data fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div className='mt-1'>Main Dashboard</div>
                  </div>
                </div>
              </NavLink>
            </li>

            <li className="sidebar-items hasSubmenu">
              <NavLink to="/dashboard" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-bar-chart fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>Clinic Analysis</div>
                  </div>
                </div>
              </NavLink>
              {/* use collapse class to hide submenu */}
              {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                <li>
                  <NavLink to="/dashboard/overview">Overview</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/configure">Settings & Configration</NavLink>
                </li>
              </ul> */}
            </li>
            <li className="sidebar-items hasSubmenu">
              <NavLink to="/statistics" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-pie-chart fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>Message Statistics</div>
                  </div>
                </div>
              </NavLink>
              {/* use collapse class to hide submenu */}
              {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                <li>
                  <NavLink to="/dashboard/overview">Overview</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/configure">Settings & Configration</NavLink>
                </li>
              </ul> */}
            </li>

            <li className="sidebar-items hasSubmenu">
              <NavLink to="/goals/general" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-bullseye fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>Setup Goals</div>
                  </div>
                </div>
              </NavLink>

              {/* <ul className="sub-menu-list ms-2 collapse" id="collapseSchedule">
                <li>
                  <NavLink to="/goals">General</NavLink>
                </li>
                <li>
                  <NavLink to="/goals">Production</NavLink>
                </li>
              </ul> */}
            </li>

            <li className="sidebar-items">
              <NavLink to="/configure" className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-gear fs-5" ></i>
                  </div>
                  <div className="txt-box">
                    <div>Settings & Configration</div>
                  </div>
                </div>
              </NavLink>
            </li>

            <li className="sidebar-items">
              <a href="https://app.trusthorizon.com/" target={'_blank'} className="d-flex">
                <div className="d-flex">
                  <div className="icon-box me-2">
                    <i className="bi bi-star-half fs-5"></i>
                  </div>
                  <div className="txt-box">
                    <div>Reviews</div>
                  </div>
                </div>
              </a>
            </li>

            <li className="sidebar-items">
              <a href="https://help.xcarepro.com/" target={'_blank'} className="d-flex">
                <div className="icon-box me-2">
                  <i className="bi bi-question-circle fs-5"></i>
                </div>
                <div className="txt-box">
                  <div>Help</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}