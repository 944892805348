import React, { useEffect,useState } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import startOfWeek from "date-fns/startOfWeek";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { IconButton, withStyles } from "@material-ui/core";
import format from "date-fns/format";
import { createStyles } from "@material-ui/styles";
import clsx from "clsx";
import isValid from "date-fns/isValid";
export const WeekModal = ({ show,minDate='',maxDate='', handleClose, handleDateChange, classes }: any) => {
    const [week, setWeek]: any = useState(null);
    
    const handleChangeWeek = (date: any) => {
        let weekStartDate: any = startOfWeek(new Date(date));
        setWeek(weekStartDate);
    }
    const makeJSDateObject = (date: any) => {
        if (date) return new Date(date);
        return null;
    }
    const renderWrappedWeekDay = (date: any, selectedDate: any, dayInCurrentMonth: any) => {
        if (date) {
            let dateClone: any = makeJSDateObject(date);
            let selectedDateClone: any = makeJSDateObject(selectedDate);

            const start = startOfWeek(selectedDateClone);
            const end = endOfWeek(selectedDateClone);

            const dayIsBetween = isWithinInterval(dateClone, { start, end });
            const isFirstDay = isSameDay(dateClone, start);
            const isLastDay = isSameDay(dateClone, end);
            const wrapperClassName = clsx({
                [classes.highlight]: dayIsBetween,
                [classes.firstHighlight]: isFirstDay,
                [classes.endHighlight]: isLastDay,
            });

            const dayClassName = clsx(classes.day, {
                [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
                [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
            });

            return (
                <div className={wrapperClassName}>
                    <IconButton className={dayClassName}>
                        <span> {format(dateClone, "d")} </span>
                    </IconButton>
                </div>
            );
        } else {
            return (<div></div>)
        }
    };
    const formatWeekSelectLabel = (date: any, invalidLabel: any) => {
        let dateClone = makeJSDateObject(date);
        return dateClone && isValid(dateClone)
            ? moment(dateClone).format('YYYY/MM/DD')
            : invalidLabel;
    };
    const handleSave = () => {
        let sDate: any, eDate: any;
        if (week) {
            sDate = moment(week).format('YYYY-MM-DD');
            eDate = moment(week).add(6, 'days').format('YYYY-MM-DD')
        } 
        setWeek(null);
        handleDateChange(sDate, eDate);
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="newcommon close-chat"
            centered
        >
            <Modal.Header>
                <div className="modal-title">Week</div>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => { handleClose() }}
                ></button>
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex flex-row m-2'>
                    <KeyboardDatePicker
                        className='m-2'
                        margin="none"
                        // id={"date-picker-dialog-week"}
                        // name={"date-picker-dialog-week"}
                        // label="Week"
                        placeholder='Week'
                        format="YYYY/MM/DD"
                        minDate={minDate}
                        maxDate={maxDate}
                        // maxDate={new Date()}
                        value={week}
                        onChange={handleChangeWeek}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        renderDay={renderWrappedWeekDay}
                        labelFunc={formatWeekSelectLabel}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    disabled={!week}
                    className="btn btn-primary"
                    onClick={() => {
                        handleSave();
                    }}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    );
};
const styles = createStyles((theme:any) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));
export default withStyles(styles)(WeekModal);