import React, { useEffect } from 'react'
import logo from '../../assets/images/logo/logo.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { useAppSelector } from '../../store/hook';
import Cookie from 'js-cookie';
import apiConfigs from '../../configs/apiConfigs';
export const Header = () => {
  const history = useNavigate();
  const userData = useAppSelector((state) => state.user.user);
  const logout = () => {
    // dispatch(userLogout());
    Cookie.remove('dashboard_cookie');
    Cookie.remove('dashboard_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
    history('/login');
  };
  useEffect(() => {
    $('.hmbg').on('click', function () {
      $(".main-sidebar").toggleClass("shrink");
      $(".main-middle-sec").toggleClass("shrink");
    });
  }, []);
  return (
    <>
      <header className="main-header d-flex flex-column flex-md-row  align-items-center shadow-sm">
        <div className="me-auto d-flex flex-row">
          <a>
            <img src={logo} />
          </a>
          <button type="button" className="btn ms-4 btn-sm hmbg"><i className="bi bi-list fs-5"></i></button>
        </div>

        {/* <div>
          <button className="btn btn-outline-primary">Switch to DSO Account</button>
        </div> */}

        <div className="dropdown">
          <div className="profile-header-box d-flex align-items-center dropdown-toggle ms-4" id="admindrop" data-bs-toggle="dropdown" aria-expanded="false">
            {/* <div className="img-box-header overflow-hidden rounded-circle me-2">
                        <img src="images/walter-heidary.jpg" className="img-fluid avatar rounded-circle" />
                    </div> */}
            <span className="avatar flex-shrink-0 avatar-48 img-circle me-2">{userData?.firstName?.charAt(0)}{userData?.lastName?.charAt(0)}</span>
            <div className="txt-header-box">
              <div className="fw-medium">{userData && userData.firstName ?userData?.firstName:'' } {userData && userData.lastName ?userData?.lastName:'' }</div>
              <span className="text-muted" style={{ fontSize: "12px" }}>Admin</span>
            </div>
          </div>
          <ul className="dropdown-menu" aria-labelledby="admindrop">
            <li><NavLink className="dropdown-item" to="/user/account">Edit profile</NavLink></li>
            {/* <li><NavLink className="dropdown-item" to="/user/account">My account</NavLink></li> */}
            <li className="dropdown-item" onClick={()=>{logout()}}>Logout</li>
          </ul>
          <div>
          </div>
        </div>
      </header>
    </>
  )
}
