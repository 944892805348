import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { useAppDispatch } from '../../store/hook';
import { useForm } from 'react-hook-form';
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';
import { addGoalSetting, getGoalSetting } from '../../services/goals';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
export const Production = () => {
  let data: any = useOutletContext();
  const dispatch = useAppDispatch();
  const [selectedClinics, setSelectedClinics] = useState([]);
  const { register, setError, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
  const [isMultipleClinicSelection, setIsMultipleClinicSelection] = useState(false);

  useEffect(() => {
    
    if (data && data.selectedClinics.length > 0) {
      setIsMultipleClinicSelection(data.isMultipleSelections);
      setSelectedClinics(data.selectedClinics);
      if (data.selectedClinics.length === 1 && data.selectedClinics[0].id !== 'all') {
        fetchSettingsData();
      } else {
        setValue('visitproductiondaily', 20);
        setValue('visitproductionweekly', 100);
        setValue('visitproductionmonthly', 400);
      }
    }
  }, [useOutletContext()]);
  
  const fetchSettingsData = () => {
    let clinicIds = _.map(data.selectedClinics, 'id');
    dispatch(loadingStart());
    getGoalSetting({ clinicId: clinicIds.toString() })
      .then((success) => {
        dispatch(loadingEnd());
        if (success && success.production_configuration) {
          setValue('visitproductiondaily', success.production_configuration.visitproductiondaily);
          setValue('visitproductionweekly', success.production_configuration.visitproductionweekly);
          setValue('visitproductionmonthly', success.production_configuration.visitproductionmonthly);
       
        } else {
          setValue('visitproductiondaily', '');
          setValue('visitproductionweekly', '');
          setValue('visitproductionmonthly', '');
        }
      })
      .catch((error) => {
        dispatch(loadingEnd());
        console.log('Error while fetching setting data', error);
      
      })
  }
  
  const updateSetting = (datas: any) => {
    if (!data || data.selectedClinics.length === 0) {
      toast.error('Select clinic');
      return;
    }
    let request = {
      clinic_id: _.map(data.selectedClinics, 'id').toString(),
      type: 'production',
      production_configuration: datas
    }
    console.log('request', request);
    dispatch(loadingStart());
    addGoalSetting(request)
      .then((success) => {
        dispatch(loadingEnd());
        if (success) {
          data.handleIsMultipleClinicSelection(false);
          fetchSettingsData();
          if (success && success.status && success.status.msg) {
            toast.success(success.status.msg);
          }
        }
      })
      .catch((error) => {
        dispatch(loadingEnd());
        console.log('Eroor while update setting', error);
      })
  }
  
  return (
    <>
      <section className="goalsetup-sec px-3 pt-4">
        <div className="container">
          <div className="d-flex mb-2">
            <h6>Clinic Goals</h6>
          </div>
          <div className="gset-list">
            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Production (Daily)</div>
              <div>is greater than</div>
              <div className="">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('visitproductiondaily', { required: true })} />
              </div>
            </div>
            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Production (Weekly)</div>
              <div>is greater than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('visitproductionweekly', { required: true })} />
              </div>
            </div>

            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Production (Monthly)</div>
              <div>is greater than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('visitproductionmonthly', { required: true })} />
              </div>
            </div>
            {
              isMultipleClinicSelection &&
              <div className='d-flex justify-content-end mt-3'>
                <button className='btn btn-primary' onClick={handleSubmit(updateSetting)}>Update</button>
              </div>
            }
          </div>
          {/* <div className="d-flex mt-3">
            <h6>Provider Goals</h6>
          </div>
          <div className="glist-item border-bottom pb-2 mb-2">
            <div>Provider 1 (Daily)</div>
            <div>is greater than</div>
            <div className="">
              <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('provider1daily', { required: true })} />
            </div>
          </div>
          <div className="glist-item border-bottom pb-2 mb-2">
            <div>Provider 1 (Weekly)</div>
            <div>is greater than</div>
            <div className="d-flex gap-2 align-items-center">
              <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('provider1weekly', { required: true })} />
            </div>
          </div>

          <div className="glist-item border-bottom pb-2 mb-2">
            <div>Provider 1 (Monthly)</div>
            <div>is greater than</div>
            <div className="d-flex gap-2 align-items-center">
              <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('provider1monthly', { required: true })} />
            </div>
          </div> */}

        </div>
      </section>
    </>
  )
}