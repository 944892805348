import React, { ContextType, useState } from 'react'
import { Outlet ,useOutletContext} from 'react-router-dom';
import { GoalsHeader } from '../../components/goals/GoalsHeader'

export const Goals = () => {
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [isMultipleSelections, setIsMultipleSelections] = useState(false);

  const handleSelectedClinics = (data: any) => {
    setSelectedClinics(data);
  }

  const handleIsMultipleClinicSelection = (data:any) => {
    setIsMultipleSelections(data);
  }
  return (
      <>
          <GoalsHeader handleSelectedClinic={handleSelectedClinics} handleIsMultipleClinicSelection={handleIsMultipleClinicSelection} isMultiSelection={isMultipleSelections} />
          <Outlet context={{selectedClinics,isMultipleSelections,handleIsMultipleClinicSelection}} />
      </>
  )
}
export function useUser() {
  return useOutletContext();
}