import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../../assets/js/canvasjs.react';
var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const PatientDiagnosed = () => {
    const [dataPoints, setDataPoints] = useState([{ name: 'May 1', y: 10,color:'#ed7d31' },{ name: 'May 1', y: 20,color:'#dadada' }]);
    useEffect(() => {
        let options = {
            animationEnabled: true,
            theme: "light2",
            // title: {
            //     text: 'UnScheduled',
            //     fontSize: 10,
            // },
            legend: {
                fontFamily: "calibri",
                fontSize: 10,
                dockInsidePlotArea: true,
                verticalAlign: "center",
                horizontalAlign: "center",
                itemWidth: 65,
                // itemTextFormatter: function (e:any) {
                //     return e.dataPoint.name + ": " + Math.round(e.dataPoint.y);
                // }
            },
            data: [{
                cursor: "pointer",
                explodeOnClick: false,
                innerRadius: "69%",
                // legendMarkerType: "square",
                name: 'Patient Diagnosed',
                radius: "100%",
                // showInLegend: true,
                startAngle: 90,
                type: "doughnut",
                // indexLabel: "{y}",
                indexLabelFormatter: function (e: any) {
                    if (e.dataPoint.y) {
                        return e.dataPoint.y;
                    }
                },
                indexLabelTextAlign: "left",
                indexLabelPlacement: "inside",
                indexLabelFontColor: "black",
                dataPoints: dataPoints
            }]
        }
        var charts = new CanvasJS.Chart("patientdiagnosed", options);
        charts.render();
    }, []);
  return (
    <div className='w-100 h-100' id="patientdiagnosed"></div>
  )
}
