/**
 * Global app configurations.
 */
const STAGING_HOST = ['dashboard.staging.xcarepro.com'];
const DEV_HOST = ['dashboard.dev.xcarepro.com', 'localhost'];
const PROD_HOST = ['dashboard.xcarepro.com'];
const hostname = window.location.hostname;
let configs: any = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    const SOURCE = "https://dev.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'superuser/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        STRIPE_CLIENT_ID: 'ca_HP3Zpj5bW5jvv0QPn54c2EaS9g3tzFUv',
        COOKIE_DOMAIN: '.dev.xcarepro.com',
        DASHBOARD_URL: 'http://app.dev.xcarepro.com',
        CHAT_COLLECTION: 'chat_dev', //'chat_dev' for dev 
        TOKEN_COLLECTION: 'token_dev', //'tokens_dev' for dev
        MESSAGE_CENTER_API: 'https://mc.dev.xcarepro.com/api/',
        CONVERSATION_DEV: 'conversation_dev',
        PATIENTS_DEV: 'patients_dev',
        GROUP_COLLECTION: 'groups_dev',
        USER_COLLECTION: 'users_dev',
        MESSAGE_COLLECTION: 'messages_dev',
        COMMUNICATION_SERVER: 'https://comu.dev.xcarepro.com/api/',
        CHART_API: 'https://anc.dev.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: 'task_manager_history_dev',
        TASK_MANAGER_DISCUSSION_COLLECTION: 'task_manger_discussion_dev',
        UTILITY_URL: 'https://utility.dev.xcarepro.com/'
    }
} else if (STAGING_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    const SOURCE = "https://staging.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'superuser/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        STRIPE_CLIENT_ID: 'ca_HP3Zpj5bW5jvv0QPn54c2EaS9g3tzFUv',
        COOKIE_DOMAIN: '.staging.xcarepro.com',
        DASHBOARD_URL: 'http://app.staging.xcarepro.com',
        CHAT_COLLECTION: 'chat', //'chat' for staging 'chat_live' for live
        TOKEN_COLLECTION: 'tokens', //'tokens' for staging 'token_live' for live
        MESSAGE_CENTER_API: 'https://mc.staging.xcarepro.com/api/',
        CONVERSATION_DEV: 'conversation_staging',
        PATIENTS_DEV: 'patients_staging',
        GROUP_COLLECTION: 'groups_staging',
        USER_COLLECTION: 'users_staging',
        MESSAGE_COLLECTION: 'messages_staging',
        COMMUNICATION_SERVER: 'https://comu.staging.xcarepro.com/api/',
        CHART_API: 'https://anc.staging.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: 'task_manager_history_staging',
        TASK_MANAGER_DISCUSSION_COLLECTION: 'task_manger_discussion_staging',
        UTILITY_URL: 'https://utility.staging.xcarepro.com/'
    }
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Production environment
    const SOURCE = "https://www.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'superuser/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        STRIPE_PUBLIC_KEY: 'pk_live_kiZnBAq9UhZ51XE48yE4PcN0008A3aLh2Z',
        STRIPE_CLIENT_ID: 'ca_HP3ZzCFwNv0eh5BTPmPtaDCgbOo2rra7',
        COOKIE_DOMAIN: '.xcarepro.com',
        DASHBOARD_URL: 'https://app.xcarepro.com',
        CHAT_COLLECTION: 'chat_live', //'chat' for staging 'chat_live' for live
        TOKEN_COLLECTION: 'token_live', //'tokens' for staging 'token_live' for live
        MESSAGE_CENTER_API: 'https://mc.xcarepro.com/api/',
        CONVERSATION_DEV: 'conversation_live',
        PATIENTS_DEV: 'patients_live',
        GROUP_COLLECTION: 'groups_live',
        USER_COLLECTION: 'users_live',
        MESSAGE_COLLECTION: 'messages_live',
        COMMUNICATION_SERVER: 'https://comu.xcarepro.com/api/',
        CHART_API: 'https://anc.xcarepro.com/',
        TASK_MANAGER_HISTORY_COLLECTION: 'task_manager_history_live',
        TASK_MANAGER_DISCUSSION_COLLECTION: 'task_manger_discussion_live',
        UTILITY_URL: 'https://utility.xcarepro.com/'
    }
}
export default configs;