import React, { useEffect,useState } from 'react';
import { AppointmentConfirmationNew } from '../../components/dashboard/appointment/AppointmentConfirmationNew';
import { AppointmentCancelNew } from '../../components/dashboard/appointment/AppointmentCancelNew';
import { AppointmentPreScreeningNew } from '../../components/dashboard/appointment/AppointmentPreScreeningNew';
import { useAppSelector } from '../../store/hook';
import { useNavigate } from 'react-router-dom';
import { MarketingBirthday } from '../../components/dashboard/marketing/MarketingBirthday';
import { MarketingEvents } from '../../components/dashboard/marketing/MarketingEvents';
import { MarketingRecall } from '../../components/dashboard/marketing/MarketingRecall';
import { MarketingDigitalDoc } from '../../components/dashboard/marketing/MarketingDigitalDoc';
import { ReminderScheduled } from '../../components/dashboard/Reminder/ReminderScheduled';
import { ReminderConfirmation } from '../../components/dashboard/Reminder/ReminderConfirmation';
import { ReminderPrescreening } from '../../components/dashboard/Reminder/ReminderPrescreening';
import { AdministrativeCompleted } from '../../components/dashboard/administrative/AdministrativeCompleted';
import { AdministrativeReserved } from '../../components/dashboard/administrative/AdministrativeReserved';
import { AdministrativeScheduledRescheduled } from '../../components/dashboard/administrative/AdministrativeScheduledRescheduled';
import { AdministrativeCancelled } from '../../components/dashboard/administrative/AdministrativeCancelled';
import { AdministrativeConfirmation } from '../../components/dashboard/administrative/AdministrativeConfirmation';
import { AdministrativePrescreening } from '../../components/dashboard/administrative/AdministrativePrescreening';

export const DashboardCharts = () => {

    const userData = useAppSelector((state) => state.user.user);
    const history = useNavigate();
    const [selectedClinic, setSelectedClinic] = useState('');

    useEffect(() => {
        if (userData && userData.clinics && userData.clinics.length > 0) {
            setSelectedClinic(userData.clinics[0].id);
        }
    }, []);

    const handleClinic = (e: any) => {
        setSelectedClinic(e.target.value);
    }
    return (
        <>
            <section className="middle-navbar px-30 py-4">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <select className='form-select' value={selectedClinic} onChange={(e: any) => { handleClinic(e) }}>
                                {
                                    (userData && userData.clinics && userData.clinics.length > 0) &&
                                    userData.clinics.map((clinic: any) => {
                                        return (
                                            <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="stp-action d-flex align-items-center">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => { history('/configure') }}><i className="bi bi-gear"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid p-4">
                <div className="row temp-chart adjstimg ">
                    <div className="col-12 d-flex flex-wrap">
                        <AppointmentConfirmationNew clinicId={selectedClinic} />
                        <AppointmentCancelNew clinicId={selectedClinic} />
                        <AppointmentPreScreeningNew clinicId={selectedClinic} />

                        <MarketingBirthday clinicId={selectedClinic} />
                        <MarketingEvents clinicId={selectedClinic} />
                        <MarketingRecall clinicId={selectedClinic} />
                        <MarketingDigitalDoc clinicId={selectedClinic} />
                        
                        <ReminderScheduled clinicId={selectedClinic} />
                        <ReminderConfirmation clinicId={selectedClinic} />
                        <ReminderPrescreening clinicId={selectedClinic} />
                        
                        <AdministrativeCompleted clinicId={selectedClinic} />
                        <AdministrativeReserved clinicId={selectedClinic} />
                        <AdministrativeScheduledRescheduled clinicId={selectedClinic} />
                        <AdministrativeCancelled clinicId={selectedClinic} />
                        <AdministrativeConfirmation clinicId={selectedClinic} />
                        <AdministrativePrescreening clinicId={selectedClinic} />
                    </div>
                </div>
            </div>
        </>
    )
}
