import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/images/logo/logo.svg';
import CONSTANT_LABELS from '../../constants/labels';
import TITLE_CONSTANTS from '../../constants/titles';
import { useForm } from 'react-hook-form';
import { NavLink,useNavigate } from 'react-router-dom';
import { dashboardLogin } from '../../services/auth';
import { isLogin, getCookie } from '../../utils/cookies';
import Cookie from 'js-cookie';
import apiConfigs from '../../configs/apiConfigs';
import { useAppSelector,useAppDispatch } from '../../store/hook';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';
export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, watch, formState: { errors }, setError } = useForm();
    const history = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.user);
    useEffect(() => {
        const login = getCookie();
        if (login) {
            history('/dashboard', { replace: true });
        }
    }, []);

    const onSubmit = (data: any) => {
        dispatch(loadingStart());
        dashboardLogin({ email: data.email, password: data.password }).then((success) => {
            dispatch(loadingEnd());
            if (success && success.status && success.status.error === false) {
                Cookie.remove('dashboard_cookie'); Cookie.remove('dashboard_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
                const key1 = 'xprUser.clinic-' + success.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'jwtToken';
                var json: any = {};
                json[key1] = true;
                json[key2] = success.uniqueId;
                json[key3] = success.authParam;
                json[key4] = success.jwtToken;
                Cookie.set('dashboard_cookie', json, { expires: 30 });
                Cookie.set('dashboard_cookie', json, { domain: apiConfigs.COOKIE_DOMAIN, url: '/', expires: 30 });
                setIsLoading(false);
                history('/dashboard', { replace: true });
            }
        }).catch((err) => {
            setIsLoading(false);
            dispatch(loadingEnd());
            console.log('errorTets2', err);
            if (err && err.error === true) {
                setError('email', { type: 'invalidemailpass', message: err.msg });
            } else {
                setError('email', { type: 'someerr', message: 'Something went wrong.' });
            }
        });
    }
    const handleForgotPassword = (e: any) => { }
    return (
        <>
            <div className="app login-container d-lg-flex min-vh-100 d-flex">
                <LoadingIndicator></LoadingIndicator>
                <div className="form-signin">
                    <Helmet><title>{TITLE_CONSTANTS.TITLES.LOGIN}</title></Helmet>
                    <div className="card shadow-sm">

                        <div className="card-header bg-light px-4 py-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <img src={logo} alt="Logo" width="100px" />
                                {/* <div className="fs-5 fw-medium">{LABEL_CONSTANTS.LABELS.LOGIN}</div> */}
                            </div>
                        </div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">{CONSTANT_LABELS.LABELS.EMAIL}</label>
                                    <input className="form-control" placeholder="Email address"
                                        {...register('email', {
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                    />
                                    {
                                        (errors && errors.email && errors.email.type === "required") &&
                                        <div className="is-invalid">Email is required.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "pattern") &&
                                        <div className="is-invalid">Email format is invalid.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "invalidemailpass") &&
                                        <div className="is-invalid">{errors.email.message}</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "someerr") &&
                                        <div className="is-invalid">{errors.email.message}</div>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">{CONSTANT_LABELS.LABELS.PASSWORD}</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password"
                                        {...register('password', {
                                            required: true,
                                        })} />
                                    {
                                        (errors && errors.password && errors.password.type === "required") &&
                                        <div className="is-invalid">Password is required.</div>
                                    }
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            <NavLink to="" className="text-decoration-none link-danger" onClick={(e) => handleForgotPassword(e)}>{CONSTANT_LABELS.LINK.FORGOT_PASSWORD}</NavLink>
                                        </span>
                                        <button className="btn btn-primary" disabled={isLoading}>
                                            {
                                                isLoading &&
                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                            }
                                            {CONSTANT_LABELS.BUTTON_LABEL.LOGIN}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
